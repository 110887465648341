import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";

const Circle = ({ ...rest }) => {
  const circle = useRef(null);
  const [timer, setTimer] = useState(null);
  const [color, setColor] = useState("#E1DFE5");

  const changeColor = () => {
    let colors = ["#9F95C1", "#9F95C1", "#60F", "#09F", "#ff4d01"];
    let chance = Math.random();
    setColor(
      chance > 0.8
        ? colors[Math.floor(Math.random() * colors.length)]
        : "#E1DFE5"
    );
  };

  const loop = () => {
    let rand = Math.round(Math.random() * (1000 - 500)) + 500;
    setTimer(
      setTimeout(() => {
        changeColor();
        loop();
      }, rand)
    );
  };

  useEffect(() => {
    if (circle.current) {
      loop();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [circle]);

  return (
    <motion.circle
      animate={{ fill: color, r: 4.875 }}
      transition={{ duration: 0.5, yoyo: "infinite" }}
      ref={circle}
      fill={color}
      r="0"
      initial={{ fill: "#e1dfe5", r: 0 }}
      {...rest}
    />
  );
};

export default Circle;
