import React, { useRef } from "react";
import { motion } from "framer-motion";

const Path = ({ ...rest }) => {
  const p = useRef(null);

  return (
    <motion.path
      animate={{pathLength: 1, pathOffset: 0}}
      initial={{pathLength: 0, pathOffset: 1}}
      transition={{duration: 2}}
      ref={p}
      {...rest}
    />
  );
};

export default Path;
