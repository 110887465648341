import React from "react";
import {motion} from "framer-motion";
import Circle from "./circle";
import Path from "./path";

const Egg = () => {
  // const [color, setColor] = useState(["#60F", "#09F", "#FA0"]);
  return (
      <motion.div className="hidden lg:flex  justify-center py-8 text-green-500" style={{marginBottom: '-124px'}}>
        <svg width="418" height="325" viewBox="0 0 418 325" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.4993 227.5H394.999C394.999 227.5 444.999 233 394.999 233C344.999 233 310.5 325 214.999 325C119.499 325 74.9993 233 23.4994 233C-28.0006 233 23.4993 227.5 23.4993 227.5Z"
            fill="white"
          />
          <motion.g whileHover={{
              x: [0, 5, -5, 4, -4, 3, -3, 2, -2, 1, -1],
              transition: {
                duration: 1.3,
                ease: "easeInOut",
                loop: "Infinity",
                repeatDelay: 0.05
              },
              rotate: [0, -10, 10, -8, 8, -6, 6, -4, 4, -3, 3, -2, 2, -1, 1, 0]
            }}
            animate={{
              x: [0, 5, -5, 4, -4, 3, -3, 2, -2, 1, -1],
              transition: {
                duration: 1.3,
                ease: "easeInOut",
                loop: "Infinity",
                repeatDelay: 10
              },
              rotate: [0, -10, 10, -8, 8, -6, 6, -4, 4, -3, 3, -2, 2, -1, 1, 0]
            }}
            whileTap={{scale: [1, 0.9]}}
            style={{originX: 0.5, originY: 0.7}}>
            <Path
              d="M331 157.817C331 230.612 277.274 289.625 211 289.625C144.726 289.625 91 230.612 91 157.817C91 85.0211 144.726 1.5 211 1.5C277.274 1.5 331 85.0211 331 157.817Z"
              fill="url(#paint0_radial)"
            />
            <Path
              animate={{pathLength: 1, pathOffset: 0}}
              initial={{pathLength: 0, pathOffset: 1}}
              transition={{duration: 2}}
              d="M152.875 273.062L105.375 93.375L314.125 222.438L254.125 14.3125"
              stroke="#E1DFE5"
            />
            <Path
              animate={{pathLength: 1, pathOffset: 0}}
              initial={{pathLength: 0, pathOffset: 1}}
              transition={{duration: 2}}
              d="M321 104L144.438 33.375L167.25 279.938L321 104Z"
              stroke="#E1DFE5"
            />
            <Path
              animate={{pathLength: 1, pathOffset: 0}}
              initial={{pathLength: 0, pathOffset: 1}}
              transition={{duration: 2}}
              d="M216 2.125L271.625 273.062L97.25 198.688L302.875 64.625L216 2.125Z"
              stroke="#E1DFE5"
            />

            <Circle  cx="237.25" cy="107.75" r="3.875"    />
            <Circle  cx="228.5" cy="67.125" r="3.875"     />
            <Circle  cx="273.5" cy="84" r="3.875"         />
            <Circle  cx="290.375" cy="140.875" r="3.875"  />
            <Circle  cx="252.25" cy="183.375" r="3.875"   />
            <Circle  cx="184.125" cy="142.125" r="3.875"  />
            <Circle  cx="154.125" cy="123.375" r="3.875"  />
            <Circle  cx="155.375" cy="160.875" r="3.875"  />
            <Circle  cx="127.875" cy="179" r="3.875"      />
            <Circle  cx="136.625" cy="215.25" r="3.875"   />
            <Circle  cx="162.875" cy="226.5" r="3.875"    />
            <Circle  cx="199.75" cy="242.75" r="3.875"    />
            <defs>
              <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(191 160.562) rotate(90) scale(144.063 120)">
                <stop stopColor="white"/>
                <stop offset="0.630208" stopColor="white"/>
                <stop offset="1" stopColor="#F7F7F8"/>
              </radialGradient>
            </defs>
          </motion.g>
        </svg>
      </motion.div>


  );
}

export default Egg;