import React from "react"
import Layout from "../components/layout"
import Blog from "../components/landingblog"
import SEO from "../components/seo"
import Egg from "../components/egg"
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import {slugify} from "../helpers";


const IndexPage = () => {
  const data = useStaticQuery(graphql`
        query {
          allContentfulCaseStudy {
            edges {
              node {
                body {
                  body
                  id
                }
                id
                intro {
                  intro
                }
                title
                isHighlighted
                cover {
                  localFile {
                    url
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }

        }
    `);



  const highlightedCaseStudy = data.allContentfulCaseStudy.edges.filter(({node}) => node.isHighlighted).length > 0 ? data.allContentfulCaseStudy.edges.filter(({node}) => node.isHighlighted)[0].node : false;

  return (
    <Layout slimHeader={`true`}>
      <SEO title="Helping businesses succeed in their digital transformation journey" description="Websites, web applications, e-commerce solutions that meet business challenges and execute visionary ideas."/>
      <div className={' w-full text-purple-800 flex justify-center h-full p-8 lg:p-0 lg:h-66.25'}>
        <div className={`container flex flex-col lg:flex-row items-start lg:items-center justify-between mx-auto`}>
          <div className={'text-left max-w-lg '}>
            {/*<h1 className={'text-4xl font-bold leading-snug'}>Exceptional custom websites and web applications</h1>*/}
            <h1 className={'text-3xl mb-4 font-bold leading-snug'}>We help you create, build and develop amazing apps, products, and services for your business.</h1>
            <h3 className={'text-xl '}>Crafting custom web solutions for challenges big and small to help earthly businesses succeed</h3>
          </div>
          <div className={'flex items-center justify-between'}>
            <Egg />
            <div className={'flex flex-col mx-0 my-4 lg:mx-4 lg:my-0'}>
              <h2 className={'font-bold text-2xl'}>Looking to partner for your next idea?</h2>
              <p>We would love to hear about it.</p>
              <p>Drop us a line at</p>
              <p><a className={'underline font-bold mr-2'} href="mailto:hello@nunuma.com">hello@nunuma.com</a> and we’ll be back in no time.</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className={'bg-purple-800 text-white py-16 '}>
            <svg className={'subtle-logo absolute'} width="164" height="199" viewBox="0 0 164 199" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M160.025 44.3282L0.000244617 0V9.33824L125.111 56.1491L160.025 44.3282Z" fill="white" fillOpacity="0.02"/>
              <path d="M0 105.072L163.768 52.4059V145.727L0 198.393V105.072Z" fill="white" fillOpacity="0.02"/>
            </svg>

          <div className={'flex flex-col lg:flex-row container mx-auto justify-between'}>
            <div className={'flex flex-col lg:flex-row w-full lg:w-2/3'}>
              <div className={'w-full lg:w-1/2'}>
                <p className={'mb-2 text-xl px-4 lg:px-0'}>What we do</p>
                <p className={'border-b border-orange-500 mb-8 w-10  mx-4 lg:mx-0'}/>
                <div className={'mb-8 w-full lg:max-w-xs px-4 lg:pl-0 py-4 pr-4'}>
                  <p className={'font-bold mb-4'}>Meeting web challenges for next generation enterprises with focus on security, privacy and reliability</p>
                  <p>The social and regulatory frameworks keep changing. The web evolves and new opportunities for growth present to take businesses to the next level. Ideas flow and implementation usually lacks. We work at the consulting and
                    implementation level to validate and implement ideas.</p>
                </div>
                <div className={'mb-8 w-full lg:max-w-xs px-4 lg:pl-0 py-4 pr-4'}>
                  <p className={'font-bold mb-4'}>Help startups get to their product faster and focus on the market
                    challenges and opportunities</p>
                  <p>Nothing has shaped the business and internet environment like startups. Teams of passionate professionals with ideas solving pain points across all industries. We help you can focus on growth, as we take care of the technical
                    aspects of bringing your idea to life.</p>
                </div>
                <div className={'mb-8 w-full lg:max-w-xs px-4 lg:pl-0 py-4 pr-4'}>
                  <p className={'font-bold mb-4'}>Getting small businesses off the ground and in the cloud fast,
                    on brand and securely</p>
                  <p>Some of the most hard working, positive and vibrant people we have met run or work as small businesses across the contry. Helping small businesses web presence and service without breaking their bank is some of the most rewarding
                    work we do.</p>
                </div>
              </div>
              <div className={'w-full lg:w-1/2'}>
                <p className={'mb-2 text-xl px-4 lg:px-0'}>Capabilities</p>
                <p className={'border-b border-orange-500 mb-8 w-10  mx-4 lg:mx-0'}/>
                <div className={'mb-8 w-full lg:max-w-xs px-4 lg:pl-0 py-4 pr-4'}>
                  <p className={'font-bold mb-4'}>Delivering security, privacy and compliance. We understand the challenges of the web today</p>
                  <p>Security, privacy and compliance are no more second thoughts. We develop web applications with all three in mind. We depend on a thorough discovery phase to understand your business unique challenges and make sure to deliver at
                    the highest standard.</p>
                </div>
                <div className={'mb-8 w-full lg:max-w-xs px-4 lg:pl-0 py-4 pr-4'}>
                  <p className={'font-bold mb-4'}>Bring innovation and custom development accross different business sizes competitevely </p>
                  <p>Each business is unique. They operate in different geographical areas, serving different sectors with vastly different challenges, resources and opportunities. We do custom work to meet the challenges of today and are there to
                    support when things change.</p>
                </div>
                <div className={'mb-8 w-full lg:max-w-xs px-4 lg:pl-0 py-4 pr-4'}>
                  <p className={'font-bold mb-4'}>Building for the web today inevitably involves integrations to bring new capabilities to businesses of all sizes </p>
                  <p>We have experience integrating with market leading solutions in payment processing, marketplaces, regulation compliant messaging and video conferencing, innovative content management and IOT platforms.</p>
                </div>
              </div>
            </div>
            <div className={'w-full lg:w-1/5'}>
              <p className={'mb-2 text-xl px-4 lg:px-0'}>From our blog</p>
              <p className={'border-b border-orange-500 mb-8 w-10 mx-4 lg:mx-0'}/>
              <Blog />
            </div>
          </div>
        </div>
        <div className={'w-full'}>
          {/*<svg className={'fill-current'} width="1920" height="90" viewBox="0 0 1920 90" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
          {/*  <path d="M0 0H1921V89C1921 89 1948.5 67 1794 38.5C1639.5 10 1801.5 93.5 1623.5 89C1445.5 84.5 1455.5 56 1437 38.5C1418.5 21 1437 89 1229 89C1021 89 1095 21 999 21C903 21 1012.5 83.5 768.5 89C524.5 94.5 1091.5 -19.5 607.5 38.5C123.5 96.5 445.5 22.5 387.5 22.5C329.5 22.5 252.5 99 194.5 48.5C136.5 -2 0 89 0 89V0Z" fill="#3E2B7D"/>*/}
          {/*</svg>*/}

          <svg className={'fill-current -m-2'} width="3851" height="120" viewBox="0 0 3851 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
              <path d="M0 4.00024H1921V93.0003C1921 93.0003 1851 69.5006 1794 42.5003C1737 15.5 1415.5 166 1576 62.5C1736.5 -41 1359 95.0005 1340.5 77.5005C1322 60.0005 1490.5 -15 1229 62.5C967.5 140 1064.5 64.0006 999 25.0003C933.5 -14 840 143.5 768.5 93.0003C697 42.5002 681 -4.00032 611 62.5C541 129 476 78.3525 387.5 26.5003C299 -25.3519 400.5 160.801 278 77.5005C155.5 -5.80051 0 93.0003 0 93.0003V4.00024Z" fill="#3E2B7D"/>
            </g>
            <g filter="url(#filter1_d)">
              <path d="M1920 4.00024H3841V93.0003C3841 93.0003 3771 69.5006 3714 42.5003C3657 15.5 3335.5 166 3496 62.5C3656.5 -41 3279 95.0005 3260.5 77.5005C3242 60.0005 3410.5 -15 3149 62.5C2887.5 140 2984.5 64.0006 2919 25.0003C2853.5 -14 2760 143.5 2688.5 93.0003C2617 42.5002 2601 -4.00032 2531 62.5C2461 129 2396 78.3525 2307.5 26.5003C2219 -25.3519 2320.5 160.801 2198 77.5005C2075.5 -5.80051 1920 93.0003 1920 93.0003V4.00024Z" fill="#3E2B7D"/>
            </g>
            <defs>
              <filter id="filter0_d" x="-10" y="0.000244141" width="1941" height="119.09" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="6"/>
                <feGaussianBlur stdDeviation="5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.219608 0 0 0 0 0.145098 0 0 0 0 0.462745 0 0 0 0.25 0"/>
                <feBlend mode="darken" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
              </filter>
              <filter id="filter1_d" x="1910" y="0.000244141" width="1941" height="119.09" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="6"/>
                <feGaussianBlur stdDeviation="5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.219608 0 0 0 0 0.145098 0 0 0 0 0.462745 0 0 0 0.25 0"/>
                <feBlend mode="darken" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
              </filter>
            </defs>
          </svg>

        </div>
        {highlightedCaseStudy && <div className={'w-full text-purple-800 flex py-8 flex-col lg:flex-row items-center justify-center mb-8'} >
          <div className={'w-full px-4 lg:p-0 lg:w-1/3 md:mr-12 mb-4 lg:mb-0 '}>
            <Img fluid={highlightedCaseStudy.cover.localFile.childImageSharp.fluid} alt={highlightedCaseStudy.title}/>
          </div>
          <div className={'w-full m-0 px-4 lg:max-w-lg lg:ml-12 '}>
            <p className={'font-bold'}>CASE STUDIES</p>
            <p className={'text-3xl md:mb-4'}>{highlightedCaseStudy.title}</p>
            <p className={'text-base mb-4'}>{highlightedCaseStudy.intro.intro}</p>
            <p className={'font-medium'}><Link to={'/case-study/' + slugify(highlightedCaseStudy.title)} className={'max-w-lg mb-8 font-bold'}>Read case study</Link></p>
          </div>
        </div>}

      </div>


    </Layout>
  )
}


export default IndexPage
